import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { IconType } from "react-icons/lib";
import { Link } from "react-router-dom";

type ListActionButtonProps = {
  /**
   * Link-Adresse, wohin der Button beim Klick zeigen soll.
   *
   * @description wird eine Adresse angegeben, dann wird der Button als Link gerendert, ansonsten wird er als "normaler" Button gerendert
   * @type {string}
   */
  to?: string;
  /**
   * Icon, welches auf dem Button angezeigt werden soll
   *
   * @description hierbei kann entweder direkt ein Icon aus React-Icons angegeben werden, ein SVG als Component, oder ein SVG, welches per "import" eingebunden wurde (wird dann als String übergeben)
   * @type {(IconType | string | React.FC<React.SVGProps<SVGSVGElement>>)}
   */
   icon: IconType | string | React.FC<React.SVGProps<SVGSVGElement>>;
  } & Omit<
  ButtonProps,
  "children" | "variant" | "size" | "className" | "to" | "as"
>;

/**
 * Button für die Verwendung in Listen. Dieser Button verhält sich wie ein normaler Button, nur mit dem Unterschied, dass der Button lediglich ein Icon beinhaltet, welches "rund" dargestellt wird.
 *
 * @param {ListActionButtonProps} {
 *   to,
 *   onClick,
 *   icon: Icon,
 *   ...rest
 * }
 * @returns
 */
export const ListActionButton: React.VFC<ListActionButtonProps> = ({
  to,
  icon: Icon,
  ...rest
}: ListActionButtonProps) => {
  const linkProps = to ? { to: to, as: Link } : { to: "" };

  if (!Icon || Icon === null) return <></>;

  const iconCtrl =
    typeof Icon === "string" ? (
      <img src={Icon} width="14px" height="14px" />
    ) : (
      <Icon />
    );

  return (
    <Button
      {...rest}
      variant="listaction"
      size="sm"
      className="mr-1 rounded-circle"
      {...linkProps}
    >
      {iconCtrl}
    </Button>
  );
};
