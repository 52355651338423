import { FieldProps } from "formik";
import { format, parseISO } from "date-fns";
import React from "react";
import { DateOrEmpty } from "./DateTypes";

export const FormikDateField: React.FC<FieldProps<DateOrEmpty>> = (props: FieldProps<DateOrEmpty>) => {
  const { field, form: _formIgnored, meta, ...restProps } = props;
  const { value, ...fieldProps } = field;
  // da der String aus dem DatePicker des Browsers auch länger sein kann (zB 12345-10-02), verwenden wir nur die letzten 10 Zeichen (zB 2345-10-02)
  const parsedDate = typeof value == "string" ? parseISO(value.slice(-10)) : value;
  const formattedDateString = value === undefined || value === "" ? "" : format(parsedDate, "yyyy-MM-dd");

  const { value: _metaValueIgnored, ...metaProps } = meta ?? { value: {} };
  restProps["value"] = formattedDateString;

  return <input type="date" value={formattedDateString} {...fieldProps} {...metaProps} {...restProps} />;
};

export const FormikDateTimeField: React.FC<FieldProps<DateOrEmpty>> = (props: FieldProps<DateOrEmpty>) => {
  const { field, form: _formIgnored, meta, ...restProps } = props;
  const { value, ...fieldProps } = field;
  // zur Sicherheit verwenden wir nur die letzten 16 Zeichen; siehe FormikDateField
  const parsedDate = typeof value == "string" ? parseISO(value.slice(-16)) : value;
  const formattedDateString = value === undefined || value === "" ? "" : format(parsedDate, "yyyy-MM-ddTHH:mm");

  const { value: _metaValueIgnored, ...metaProps } = meta ?? { value: {} };
  restProps["value"] = formattedDateString;

  return <input type="datetime-local" value={formattedDateString} {...fieldProps} {...metaProps} {...restProps} />;
};
