import React from "react";
import { Alert, Toast } from "react-bootstrap";
import Portal from "react-overlays/Portal";

import { format } from "date-fns";

import { useNotification } from "./useNotification";
import { NotificationObject, NotificationType } from "./NotificationProvider";

interface NotificationProps {
  notification?: NotificationObject;
  toasts?: Array<Toast>;
}

export interface Toast {
  id: string;
  message: string;
  status: string;
  type?: NotificationType;
  time?: Date;
}

export const Notification: React.VFC<NotificationProps> = ({
  notification,
  toasts,
}: NotificationProps) => {
  // hooks
  const { removeNotification, removeToast } = useNotification();

  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  const portalTarget = document.body;

  // handler
  const handleCloseNotification = () => {
    removeNotification();
  };

  const handleCloseToast = (id: string) => {
    removeToast(id);
  };

  if (notification)
    return (
      <Portal container={portalTarget}>
        <div
          aria-live="polite"
          aria-atomic="true"
          className="position-fixed w-100 d-flex flex-column p-2 align-items-center"
          style={{ zIndex: 2150, top: 0 }}
        >
          <Alert
            variant="danger"
            className="w-75"
            dismissible
            onClose={handleCloseNotification}
          >
            {notification && notification.status && (
              <Alert.Heading>{notification.status}</Alert.Heading>
            )}
            {notification && notification.message && (
              <p>({notification.message})</p>
            )}
          </Alert>
        </div>
      </Portal>
    );

  if (toasts && toasts.length > 0) {
    return (
      <Portal container={portalTarget}>
        <div
          aria-live="polite"
          aria-atomic="true"
          className="position-fixed p-2"
          style={{ zIndex: 2150, top: 0, right: 0 }}
        >
          {toasts.map((item) => (
            <Toast
              key={item.id}
              onClose={() => handleCloseToast(item.id)}
              className="ml-auto bg-white"
              delay={10000}
              autohide
            >
              <Toast.Header>
                <strong className="mr-auto">{item.status}</strong>
                {item.time && (
                  <small className="ml-1">
                    {format(item.time, "HH:mm:ss")}
                  </small>
                )}
              </Toast.Header>
              {item.message && <Toast.Body>{item.message}</Toast.Body>}
            </Toast>
          ))}
        </div>
      </Portal>
    );
  }

  return null;
};
