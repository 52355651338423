import React from "react";
import { Button, Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import classNames from "classnames/bind";

import styles from "./Tile.module.scss";
export interface TileProps {
  /**
   * Titel für den Tile. Der Titel wird in Uppercase angezeigt.
   */
  title: string;
  /**
   * URL auf die der Start-Button verweisen soll
   */
  toPath: string;
  /**
   * Pfad zu einem Icon, welches auf dem Tile angezeigt wird
   */
  iconPath: string;
  /**
   * Handler für OnClick-Events, wenn der Start-Button angeklickt wird
   */
  onclick?: React.MouseEventHandler<HTMLElement> | undefined;
  /**
   * Optionaler Hilfe-Text für den Tile. Wenn vorhanden, wird dieser in Form eines Overlays über den Titel und dem Icon angezeigt
   */
  helpText?: string | JSX.Element;
}

const cx = classNames.bind(styles);
const className = cx({
  tile_action: true,
  "t-1": true,
});

export const Tile: React.FC<TileProps> = ({
  title,
  toPath,
  iconPath,
  onclick,
  helpText,
}: TileProps) => {
  const bodyClassName = cx("d-flex", "flex-column", "justify-content-between", {
    cardbody__help: !!helpText,
  });

  return (
    <Col md={6} lg={4} xl={3} className="mt-3">
      <Card className="text-center border-light h-100">
        <Card.Body className={bodyClassName}>
          <Card.Title
            className="text-uppercase"
            style={{ fontSize: "1.25rem" }}
          >
            {title}
          </Card.Title>
          <Card.Text>
            <img
              alt="Help-Icon"
              src={iconPath}
              className="mt-4 mb-4"
              width={60}
              height={60}
            />
            {helpText && (
              <figcaption className={styles.help}>{helpText}</figcaption>
            )}
            <Button
              variant="outline-primary"
              className={className}
              as={Link}
              to={toPath}
              onClick={onclick}
            >
              Starten
            </Button>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  );
};
